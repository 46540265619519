<template>
	<div class="app-container" v-loading="infoLoading">
		<div class="filter-container">
			<div style="display: flex;">
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">拼团编码: </label>
					<el-input v-model="searchData.activityCode" clearable style='width:200px' placeholder="请输入拼团编码"></el-input>
					<!-- 时间筛选 -->
					<div class="filter-item">
						<label class="label" style="margin-left: 20px;">拼团发起时间:</label>
						<el-date-picker
							v-model="applyTime"
							type="datetimerange"
							range-separator="～"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							align="right"
							value-format="yyyy-MM-dd HH:mm:ss"
						></el-date-picker>
					</div>
					<!-- 时间筛选 -->
					<div class="filter-item">
						<label class="label" style="margin-left: 20px;">成团时间:</label>
						<el-date-picker
							v-model="joinTime"
							type="datetimerange"
							range-separator="～"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							align="right"
							value-format="yyyy-MM-dd HH:mm:ss"
						></el-date-picker>
					</div>
				</div>
			</div>
			
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<div class="filter-item">
					<label class="label">拼团状态:</label>
					<el-select
						v-model="searchData.activeType"
						placeholder="请选择"
						filterable
						clearable
						style="width: 160px;margin-right: 10px;"
					>
						<el-option
							v-for="(item,index) in activityStateList"
							:key="index"
							:label="item.str"
							:value="item.state"
						></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label">团长账号: </label>
					<el-input v-model="searchData.leaderName" clearable style='width:200px' placeholder="请输入团长账号"></el-input>
				</div>
				<div class="filter-item">
					<label class="label">团员账号: </label>
					<el-input v-model="searchData.personName" clearable style='width:200px' placeholder="请输入团员账号"></el-input>
				</div>
				<div class="filter-item">
					<label class="label">拼团商品: </label>
					<el-input v-model="searchData.goodsName" clearable style='width:200px' placeholder="请输入拼团商品名称"></el-input>
				</div>
			</div>
			<!-- button -->
			<div class="filter-item" style="margin-top: -10px;">
				<el-button type="primary" style="width:100px;margin-right:20px;" @click="search()">搜索</el-button>
				<el-button type="primary" style="width:100px;margin-right:20px;" v-loading="exportLoading" @click="esportData()">导出数据</el-button>
				<el-button type="infor" style="width:100px;margin-right:20px;" @click="clearSearch()">清空</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column prop="groupCode" label="拼团编码"></el-table-column>
				<el-table-column label="团长" >
					<template slot-scope="scope">
						<div>{{scope.row.chiefPhoneNumber}}</div>
					</template>
				</el-table-column>
				<el-table-column label="拼团商品" >
					<template slot-scope="scope">
						<div>{{scope.row.goodsName}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="beginTime" label="拼团发起时间" ></el-table-column>
				<el-table-column prop="successTime" label="成团时间"></el-table-column>
				<el-table-column prop="groupPeopleCount" label="所需成团人数"></el-table-column>
				<el-table-column prop="joinPeopleCount" label="已参团人数"></el-table-column>
				<el-table-column prop="stateValue" label="拼团状态"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<div class="table-button" @click="handleDataShow(scope.row)">详情</div>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination
				v-if="searchData.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="searchData.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="searchData.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchData.total"
			></el-pagination>
		</div>
		<el-dialog title="拼团详情" width="1300px" :visible.sync="groupInfoVisible">
			<div style="margin-bottom:30px;font-size: 18px;color: #333;">拼团信息</div>
			<div style="font-size: 14px;">
				<div class="flex center">
					<div style="width: 280px;">拼团编码: <span style="color: #027db4;">{{currentRow.groupCode}}</span> </div>
					<div style="width: 250px;">团长: <span style="color: #027db4;">{{groupDetail.chiefPhoneNumber}}</span> </div>
					<div style="width: 280px;">拼团发起时间: <span style="color: #027db4;">{{groupDetail.beginTime}}</span> </div>
					<div>成团时间: <span style="color: #027db4;">{{groupDetail.successTime}}</span> </div>
				</div>
				<div class="flex center" style="margin-top: 40px;">
					<div style="width: 280px;">所需成团人数: <span style="color: #027db4;">{{groupDetail.groupPeopleCount}}</span> </div>
					<div style="width: 250px;">已参团人数: <span style="color: #027db4;">{{groupDetail.joinPeopleCount}}</span> </div>
					<div>拼团结束时间: <span style="color: #027db4;">{{groupDetail.endTime}}</span> </div>
				</div>
				<div class="flex center" style="margin-top: 40px;">
					<div style="width: 280px;">拼团状态: <span style="color: #027db4;">{{currentRow.stateValue}}</span> </div>
					<div>拼团商品: <span style="color: #027db4;">{{groupDetail.goodsName}}</span> </div>
				</div>
			</div>
			<div style="margin-bottom:20px;font-size: 18px;color: #333;border-top: 1px solid #dddddd;padding-top: 20px;margin-top: 20px;">团员信息</div>
			<el-table :data="groupDetail.detailInfos" v-loading="loading" border>
				<el-table-column label="拼团身份">
					<template slot-scope="scope">
						<div>{{scope.row.userIdentity==1?'团长':'团员'}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="nickName" label="昵称" ></el-table-column>
				<el-table-column label="等级" >
					<template slot-scope="scope">
						<div>{{ scope.row.pusherLevel | getDistributRoleName }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="phoneNumber" label="账号" ></el-table-column>
				<el-table-column prop="groupPrice" label="拼团价"></el-table-column>
				<el-table-column prop="count" label="购买数量"></el-table-column>
				<el-table-column prop="groupMoney" label="拼团总价"></el-table-column>
				<el-table-column prop="createDate" label="参团时间"></el-table-column>
				<el-table-column  label="售后状态">
					<template slot-scope="scope">
						<div v-if="scope.row.status==0">待支付</div>
						<div v-if="scope.row.status==1">已支付</div>
						<div v-if="scope.row.status==2">已退款</div>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
import {
		groupData,
		groupDataDetail,
		exportGroupData,
	} from '@/api/goods.js'
	import utils from '@/utils/util.js';
import buttonPermissions from '@/components/buttonPermissions';
export default {
    name:'refundAfterOrder',
	components: {
		buttonPermissions
	},
	data () {
		return {
			applyTime: '',  //时间
			joinTime:'',//成团时间
			searchData: {
				activityCode:'',//拼团编码
				goodsName:'',//拼团商品
				applyStartTime: '',    //开始时间
                applyEndTime: '',      //结束时间
				leaderName:'',//团长名称
				personName:'',//团员名称
				total: 0,
				pageNo: 1,
				size: 20,
				activeType: null,
				joinStartTime: '',    //成团开始时间
				joinEndTime: '',      //成团结束时间
			},
            loading: false,
			tableData: [],
			activityStateList: [{
				state: null,
				str: '全部'
			},
			{
				state: 1,
				str: '进行中'
			},
			{
				state: 2,
				str: '已成团'
			},
			{
				state: 3,
				str: '已失效'
			},
			],
			groupInfoVisible:false,
			groupId:0,
			groupDetail:{},
			infoLoading:false,
			currentRow:{},
			exportLoading:false,
		}
	},
	async beforeMount () {
		this.groupId = this.$route.query.id;
		this.getList();
	},
	computed: {

	},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true;
			try {
				if(this.applyTime){
					this.searchData.applyStartTime = this.applyTime[0];
					this.searchData.applyEndTime = this.applyTime[1];
				}else{
					this.searchData.applyStartTime = '';
					this.searchData.applyEndTime = '';
				}
				if(this.joinTime){
					this.searchData.joinStartTime = this.joinTime[0];
					this.searchData.joinEndTime = this.joinTime[1];
				}else{
					this.searchData.joinStartTime = '';
					this.searchData.joinEndTime = '';
				}
				let data = {
					pageNo:this.searchData.pageNo,
					pageSize : this.searchData.size,
					activityId:this.groupId,
					goodsName:this.searchData.goodsName,
					groupBegintime:this.searchData.applyStartTime,
					groupCode:this.searchData.activityCode,
					//1-进行中 2-已成团 3-已失效
					groupStatus:this.searchData.activeType,
					groupEndTime:this.searchData.applyEndTime,
					chiefPhoneNumber:this.searchData.leaderName,
					memberPhoneNumber:this.searchData.personName,
					successBegintime:this.searchData.joinStartTime,
					successEndTime:this.searchData.joinEndTime,
				};
				let result = '';
				result = await groupData(data);
				result.data.records.map(item=>{
					if(item.groupStatus){
						item.stateValue = this.activityStateList[item.groupStatus].str;
					}
					return item;
				})
				this.tableData = result.data.records;
				this.searchData.total = result.data.total;
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		//搜索
		search () {
			this.searchData.pageNo = 1
			this.searchData.isDownload = false;    //下载文件标记
			if (!this.searchData.total) {
				this.searchData.size = 20
			}
			this.getList()
		},
		//详情
		handleDataShow (row) {
			this.getGroupDetail(row);
		},
		// 获取getGroupDetail
		async getGroupDetail (row) {
			this.infoLoading = true;
			try {
				let data = {
					id:row.id,
				};
				let result = '';
				result = await groupDataDetail(data);
				this.groupDetail = result.data;
				this.currentRow = row;
				this.groupInfoVisible = true;
			} catch (error) {
				console.log(error);
				this.infoLoading = false
			} finally {
				this.infoLoading = false;
			}
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.searchData.size = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.searchData.pageNo = val;
			this.getList();
		},
		clearSearch(){
			this.searchData = {
				activityCode:'',//拼团编码
				goodsName:'',//拼团商品
				applyStartTime: '',    //开始时间
				applyEndTime: '',      //结束时间
				total: 0,
				pageNo: 1,
				size: 20,
				activeType: null,
				leaderName:'',//团长名称
				personName:'',//团员名称
				joinStartTime: '',    //成团开始时间
				joinEndTime: '',      //成团结束时间
			}
			this.applyTime = '';
			this.joinTime = '';
			this.search();
		},
		//导出
		async esportData(){
			try{
				this.exportLoading = true;
				if(this.applyTime){
					this.searchData.applyStartTime = this.applyTime[0];
					this.searchData.applyEndTime = this.applyTime[1];
				}else{
					this.searchData.applyStartTime = '';
					this.searchData.applyEndTime = '';
				}
				if(this.joinTime){
					this.searchData.joinStartTime = this.joinTime[0];
					this.searchData.joinEndTime = this.joinTime[1];
				}else{
					this.searchData.joinStartTime = '';
					this.searchData.joinEndTime = '';
				}
				let data = {
					isDownload: true,    //下载文件标记
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					activityId:this.groupId,
					goodsName:this.searchData.goodsName,
					groupBegintime:this.searchData.applyStartTime,
					groupCode:this.searchData.activityCode,
					groupStatus:this.searchData.activeType,
					groupEndTime:this.searchData.applyEndTime,
					chiefPhoneNumber:this.searchData.leaderName,
					memberPhoneNumber:this.searchData.personName,
					successBegintime:this.searchData.joinStartTime,
					successEndTime:this.searchData.joinEndTime,
					
				};
				let res = await exportGroupData(data);
				utils.convertRes2Blob(res);
				this.$message({
					type: 'success',
					message: '导出成功'
				});
			}catch(e){
				this.exportLoading = false;
				//TODO handle the exception
			}finally{
				this.exportLoading = false;
			}
		},
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background-color: #fff;
}

.input-item {
	margin-left: 5px;
}

.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

/deep/.el-table__fixed-body-wrapper {
	background: #ffffff;
}
/deep/.el-table__fixed-header-wrapper {
	background: #f8f8f9;
}
</style>
